import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Fade from '@mui/material/Fade';
import Grid from '@mui/material/Grid';
import Modal from '@mui/material/Modal';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import * as React from 'react';

const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

export default function LoginDialog(props) {
    const [values, setValues] = React.useState({
        username: '',
        password: ''
    });

    const handleChange = (prop) => (event) => {
        console.log("handleChange " + prop + " = " + event.target.value);
        setValues({ ...values, [prop]: event.target.value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        setValues({ ...values, isLoggingIn: true });
        props.onStartLogin({
            username: values.username,
            password: values.password
        });
    }

    return  <Modal open={props.open}>
                <Box sx={modalStyle}>
                    <form onSubmit={handleSubmit}>
                        <Grid container style={{margin: '10px'}}>
                            <Grid item xs={10}>
                                <Typography variant="h6" component="h2">
                                    Login 
                                </Typography>

                            </Grid>
                            <Grid item xs={2}>
                                <Box sx={{ height: 40 }}>
                                    <Fade in={props.loggingIn}
                                        style={{ transitionDelay: props.loggingIn ? '800ms' : '0ms' }}
                                        unmountOnExit>
                                        <CircularProgress />
                                    </Fade>
                                </Box>
                            </Grid>
                        </Grid>
                        <Stack spacing={2}>
                            <TextField label="Username" disabled={props.loggingIn} value={values.username} onChange={handleChange('username')} />
                            <TextField label="Password" disabled={props.loggingIn} value={values.password} onChange={handleChange('password')} type="password" />
                            <Button variant="contained" type="submit" disabled={props.loggingIn}>Log in</Button>
                        </Stack>
                    </form>
                </Box>
            </Modal>
}
