import React from 'react';
import './App.css';
import { KeyMap } from './KeyMap';
import LoginDialog from './LoginDialog';

class App extends React.Component {
  started = false;

  constructor(){
    super();
    
    this.canvas = React.createRef();
    this.state = {
      width: 640,
      height: 480,
      isLoggingIn: false,
      loginWindowOpen: true
    };
  }

  handleStartLogin(loginData) {
    this.setState({isLoggingIn: true});
    window.Module.ccall('VpEmscriptenGui_startLogin', null, ['string', 'string'], [loginData.username, loginData.password]);
  }

  handleLoginResult(rc) {
    this.setState({isLoggingIn: false});
    if (!rc) {
      this.setState({loginWindowOpen: false});
    }
  }

  handleCanvasKeyDown(e) {
    console.log("keyDown: ", e.keyCode);
    const mappedKey = KeyMap[e.keyCode];
    if (mappedKey === undefined) {
      return;
    }
    e.preventDefault();
    e.stopPropagation();
    window.Module.ccall('VpEmscriptenGui_keyDown', null, ['number'], [mappedKey]);
  }

  handleCanvasKeyUp(e) {
    console.log("keyUp: ", e.keyCode);
    const mappedKey = KeyMap[e.keyCode];
    if (mappedKey === undefined) {
      return;
    }
    e.preventDefault();
    e.stopPropagation();
    window.Module.ccall('VpEmscriptenGui_keyUp', null, ['number'], [mappedKey]);
  }

  render() {
    return (
      <div className="App">
        <LoginDialog open={this.state.loginWindowOpen}
                     loggingIn={this.state.isLoggingIn}
                     onStartLogin={loginData => this.handleStartLogin(loginData)}></LoginDialog>
        
        <canvas ref={this.canvas}
                style={{width: this.state.width, height: this.state.height}}
                tabIndex="1"
                onKeyDown={e => this.handleCanvasKeyDown(e)}
                onKeyUp={e => this.handleCanvasKeyUp(e)}></canvas>
      </div>
    );

  }
  
  handleOpen() { 
    console.log("open")
    this.setState({ drawerOpen: true }); }
  handleClose() { this.setState({ drawerOpen: false }); }

  componentDidMount() {
    if (this.started) {
      return;
    }

    this.startVp();
    this.updateSize();
  }

  updateSize() {
    this.setState({
      width: window.innerWidth,
      height: window.innerHeight
    });
  }

  startVp() {
    this.started = true;
    console.log("starting vp");

    const m = {
      preRun: [],
      postRun: [],
      print: function() {
          return function(t) {
              console.log(t);
          }
      }(),
      setStatus: function(e) {
        console.log("setStatus:", e);
      },
      totalDependencies: 0,
      monitorRunDependencies: function(e) {
          this.totalDependencies = Math.max(this.totalDependencies, e);
          m.setStatus(e ? "Preparing... (" + (this.totalDependencies - e) + "/" + this.totalDependencies + ")" : "All downloads complete.");
      },
      canvas: this.canvas.current,
      onLoginResult: rc => this.handleLoginResult(rc)
    };
    
    m.setStatus("Downloading...");
    
    window.Module = m;
    window.onerror = function(e) {
      m.setStatus("Exception thrown, see JavaScript console");
      m.setStatus = function(e) {
          e && console.error("[post-exception status] " + e)
      }
    };
    window.onresize = () => {
      this.updateSize();
    };
    
    const js = document.createElement("script");
    js.src = "/VirtualParadise_Emscripten.js";
    js.async = true;
    document.head.appendChild(js);
  }
}

export default App;
