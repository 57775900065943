const VpKeys = {
  UP: 0,
  DOWN: 1,
  LEFT: 2,
  RIGHT: 3,
  ADD: 4,
  SUBTRACT: 5,
  INSERT: 6,
  DELETE: 7,
  PAGEUP: 8,
  PAGEDOWN: 9,
  HOME: 10,
  END: 11,
  ASTERISK: 12,
  SLASH: 13,
  STRAFE_LEFT: 14,
  STRAFE_RIGHT: 15,
  SHIFT: 16,
  CTRL: 17,
  ALT: 18,
  JUMP: 19
};

export const KeyMap = {
  37: VpKeys.LEFT,
  38: VpKeys.UP,
  39: VpKeys.RIGHT,
  40: VpKeys.DOWN,
  [' '.charCodeAt(0)]: VpKeys.JUMP,
  17: VpKeys.CTRL,
  ['Z'.charCodeAt(0)]: VpKeys.CTRL,
  16: VpKeys.SHIFT,
  ['W'.charCodeAt(0)]: VpKeys.UP,
  ['A'.charCodeAt(0)]: VpKeys.STRAFE_LEFT,
  ['S'.charCodeAt(0)]: VpKeys.DOWN,
  ['D'.charCodeAt(0)]: VpKeys.STRAFE_RIGHT,
  187: VpKeys.ADD,
  189: VpKeys.SUBTRACT
};
